$grey-white: #dfdfdf;
$grey-lightest: #EAEDF0;
$blue: #3997e2;

.expanded {
  .itemTitle::before {
    transform: rotate(90deg) scale(1.1);
  }
}

.itemTitle {
  background-color: #fff;
  border-top: 1px solid $grey-lightest;
  font-family: 'Helvetica Now Display', 'sans-serif';
  font-weight: 500;
  padding: 10px 20px;
}

.itemTitle::before {
  content: '›';
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding-bottom: 5px;
  color: $blue;
  font-size: 15pt;
  transform: scale(1.2);
  transition: all 0.4s ease;
}

.itemContent {
  border-top: 1px solid $grey-lightest;
  background: rgba(248,250,252,0.5);
  padding: 10px;
  line-height: 2;
}

.tooltipStyle {
  width: 310px;
}
