.CustomCheckbox {
    label {
      align-items: center;
      display: inline-flex;
      width: 100%;
      span:last-child {
        width: 80%;
      }
    }
  }
  