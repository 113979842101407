.separator {
  background:  #F2F6F9;
  width: 2px;
  height: 100%;
  margin: 0 20px 0 20px;
}

.icon {
  cursor: pointer;
}

.logo {
  margin-left: 12px;
  max-height: 36px;
  cursor: pointer;
  position: relative;
  top: -4px;
}

.circleButton {
  display: flex;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  box-shadow: 0px 3px 10px 0px rgba(170,181,189,0.50);
  align-items: center;
  justify-content: center;
}
