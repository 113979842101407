$default-color: #3997e2;
$success-color: #2eae8f;
$error-color: #eb5565;

:local {
  .default {
    height: 100%;
    width: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .general {
    @extend .default;
    background-color: $default-color;
  }

  .error {
    @extend .default;
    background-color: $error-color;
  }

  .success {
    @extend .default;
    background-color: $success-color;
  }
}
