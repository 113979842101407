.mask {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  &.closableMask {
    cursor: pointer;
  }
}

.wrapper {
  background-color: #fff;
  border-radius: 4px;
  cursor: initial;
}

.modalCloseBtn {
  justify-content: center;
  align-items: center;
  margin-left: auto;
}
