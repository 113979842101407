$grey-lightest: #DBE2E8;
$grey: #677786;
$blue: #3997E2;

.itemTitle {
  padding: 10px 20px 10px 0;
  color: $grey;
}

.itemTitle::before {
  content: '› ';
  color: $blue;
  font-size: 18pt;
}

iFrame {
  width: 900px;
  height: 500px;
  border: none;
}
