@import '~normalize.css/normalize';
@import url("https://use.typekit.net/aco3end.css");

/* Helvetica Now Display */
@font-face {
  font-family: "Helvetica Now Display";
  src: url('fonts/HelveticaNowDisplay/HelveticaNowDisplay-Regular.ttf');
  src: url('fonts/HelveticaNowDisplay/HelveticaNowDisplay-Regular.ttf') format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Helvetica Now Display";
  src: url('fonts/HelveticaNowDisplay/HelveticaNowDisplay-RegIta.ttf');
  src: url('fonts/HelveticaNowDisplay/HelveticaNowDisplay-RegIta.ttf') format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Helvetica Now Display";
  src: url('fonts/HelveticaNowDisplay/HelveticaNowDisplay-Bold.ttf');
  src: url('fonts/HelveticaNowDisplay/HelveticaNowDisplay-Bold.ttf') format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Helvetica Now Display";
  src: url('fonts/HelveticaNowDisplay/HelveticaNowDisplay-BoldIta.ttf');
  src: url('fonts/HelveticaNowDisplay/HelveticaNowDisplay-BoldIta.ttf') format("truetype");
  font-weight: bold;
  font-style: italic;
}

/* Brush Script */
@font-face {
  font-family: "Brush Script MT";
  src: url('fonts/BrushScript/BrushScriptMT.ttf') format("truetype");
  font-weight: 400;
  font-style: normal;
}


html {
  box-sizing: border-box;
}

html,
body {
  background: #f6f9fc;
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Helvetica Now Display', 'sans-serif';
  font-size: 14px;
  color: #3a4951;
  line-height: 1.42857143;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

h1, h2 {
  font-family: 'Rocky', 'Helvetica Now Display', sans-serif;
}

input, textarea, select, button {
  font-family: 'Helvetica Now Display', 'sans-serif';
  font-size: 14px;
}

a {
  display: inline-block;
  width: auto;
  color: #003146;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.16px;
  text-decoration: none;
  outline: none;
  opacity: 1;
  transition: opacity 0.3s ease-out;
}

a:hover {
  text-decoration: none;
}

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/**
 * Form styles / override defaults
 */
$placeholderColor: #aebdc4;

/** Placholder color vender overrides **/
::-webkit-input-placeholder {
  /* Chrome */
  color: $placeholderColor;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: $placeholderColor;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: $placeholderColor;
  opacity: 1;
}
:-moz-placeholder {
  /* Firefox 4 - 18 */
  color: $placeholderColor;
  opacity: 1;
}
