$scrollableContentBGColor: #F4F7F9;

.hideOnPrint {
  @media print {
    display: none;
  }
}

.showOnPrint {
  display: none;

  @media print {
    display: inherit;
  }
}

.termsAndConditionsContent {
  border: 1px solid rgb(143, 157, 168);
  padding: 2em;
  background: $scrollableContentBGColor;
  height: 45vh;
  min-height: 200px;
  overflow-y: scroll;
  @media print {
    border: none;
    padding: 0;
    background: white;
    height: auto;
    overflow: visible;
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  border: 1px solid $scrollableContentBGColor
}
