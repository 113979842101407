@import '../../../styles/base';

.default {
  z-index: $zIndexCartSummary;
  opacity: 0;
  position: fixed;
  top: 0px;
  right: 20px;
  transition: all ease 0.5s;
  pointer-events: none;
}

.pointer {
  cursor: pointer;
}

.open {
  composes: default;
  opacity: 1;
  top: 76px;
  pointer-events: auto;
}

.circleButton {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  box-shadow: 0px 3px 10px 0px rgba(170,181,189,0.50);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.row {
  display: flex;
  align-items: center;
  width: 135px;
}
