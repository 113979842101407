.enter {
  opacity: 0.01;
}

.enterActive {
  opacity: 1;
  transition: all 0.3s ease-in;
}

.leave {
  opacity: 1;
  height: 100px;
}

.leaveActive {
  opacity: 0.01;
  height: 0;
  transition: all 0.3s ease-out;
}

.leaveMessage {
  opacity: 1;
}

.leaveMessageActive {
  opacity: 0.01;
  transition: all 0.2s ease-out;
}
