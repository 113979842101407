.basicKeyRow {
  width: 100%;
  height: 50px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.expandedKeyRow {
  composes: basicKeyRow;
  height: 80px;
}

.titleKeyRow {
  composes: basicKeyRow;
  background-color: rgba(185,197,205,0.2);
}

.spaceBasicRow {
  height: 50px;
  width: 20px;
}

.spaceTitleRow {
  background-color: rgba(185,197,205,0.2);
  height: 50px;
  width: 20px;
}
