$transitionDuration: 0.3s;
$transitionCurve: ease-in-out;

$primaryClr: #1677ff;
$primaryHoverClr: #1365d9;
$primaryDisabled: #9bc5ff;

$secondaryClr: #f0f0f0;
$secondaryHoverClr: #d9d9d9;
$secondaryDisabled: #f5f5f5;

$specialClr: #26cd37;
$specialHoverClr: #20ae2f;
$specialDisabled: #a2eaa9;

$btnBorderRadius: 6px;
$btnPadding: 8px 16px;
$largeBtnFont: 16px;
$medBtnFont: 14px;

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: $btnBorderRadius;
  border: none;
  padding: $btnPadding;
  box-shadow: none;
  cursor: pointer;
  transition: all $transitionCurve $transitionDuration;
  gap: 8px;

  &:disabled {
    cursor: not-allowed;
  }
}

.bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

// Sizes
.large {
  font-size: $largeBtnFont;
  min-height: 40px;
}
.medium {
  font-size: $medBtnFont;
  min-height: 36px;
}

// Types
// Flat buttons
.primary-flat {
  background-color: $primaryClr;
  color: #fff;
  svg g path {
    fill: #fff;
  }
  &:hover,
  &:focus {
    background-color: $primaryHoverClr;
  }

  &:disabled {
    background-color: $primaryDisabled;
    color: #c7deff;
    svg g path {
      fill: #c7deff;
    }
  }
}

.secondary-flat {
  background-color: $secondaryClr;
  color: #262626;
  svg g path {
    fill: #262626;
  }
  &:hover,
  &:focus {
    background-color: $secondaryHoverClr;
  }

  &:disabled {
    color: $secondaryHoverClr;
    background-color: $secondaryDisabled;
    svg g path {
      fill: $secondaryHoverClr;
    }
  }
}

.special-flat {
  background-color: $specialClr;
  color: #fff;
  svg g path {
    fill: #fff;
  }
  &:hover,
  &:focus {
    background-color: $specialHoverClr;
  }

  &:disabled {
    background-color: $specialDisabled;
    color: #cbf3cf;
    svg g path {
      fill: #cbf3cf;
    }
  }
}

// Outline types
.primary-outline {
  background-color: #fff;
  border: 1px solid $primaryClr;
  color: $primaryClr;
  svg g path {
    fill: $primaryClr;
  }
  &:hover,
  &:focus {
    border-color: $primaryHoverClr;
    color: $primaryHoverClr;
    svg g path {
      fill: $primaryHoverClr;
    }
  }

  &:disabled {
    border-color: $primaryDisabled;
    color: $primaryDisabled;
    svg g path {
      fill: $primaryDisabled;
    }
  }
}

.secondary-outline {
  background-color: #fff;
  border: 1px solid #bfbfbf;
  color: #262626;
  svg g path {
    fill: #262626;
  }
  &:hover,
  &:focus {
    border-color: #8c8c8c;
    background-color: #fcfcfc;
  }

  &:disabled {
    border-color: #f0f0f0;
    background-color: #fff;
    color: #acacac;
    svg g path {
      fill: #acacac;
    }
  }
}

.special-outline {
  background-color: #fff;
  border: 1px solid $specialClr;
  color: $specialClr;
  svg g path {
    fill: $specialClr;
  }

  &:hover,
  &:focus {
    border-color: $specialHoverClr;
    color: $specialHoverClr;
    svg g path {
      fill: $specialHoverClr;
    }
  }

  &:disabled {
    border-color: $specialDisabled;
    color: $specialDisabled;
    svg g path {
      fill: $specialDisabled;
    }
  }
}

// Link buttons
.link {
  background-color: transparent;
  color: #1677ff;
  svg g path {
    fill: #1677ff;
  }
  &:hover {
    color: #1365d9;
    svg g path {
      fill: #1365d9;
    }
  }
  &:disabled {
    color: #c7deff;
    svg g path {
      fill: #c7deff;
    }
  }
}

// Ghost btn - Flat btn with white bg
.ghost {
  background-color: #fff;
  color: #262626;
  svg g path {
    fill: #262626;
  }
  &:hover,
  &:focus {
    background-color: #f5f5f5;
  }

  &:disabled {
    background-color: #f0f0f0;
  }
}
