@import '../../../../styles/base';

.default {
  position: fixed;
  width: 100%;
  z-index: $zindexNotification;
  transition: top .5s ease;

  ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
  }
}

.closed {
  @extend .default;
  top: -250px;
}

.opened {
  @extend .default;
  top: 56px;
}
