.basicRow {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.titleRow {
  composes: basicRow;
  background-color: rgba(185,197,205,0.2);
}

.expandedRow {
  composes: basicRow;
  height: 80px;
}

